<template>
  <div class="stepContent commonClass">
    <h3 class="titHead">供试品配制</h3>
    <div class="detail">
      <div class="flex  backReturn">
        <h4 class="title">供试品配制复核</h4>
        <div>
          <el-button size="small" plain class="return" @click="exportExcel">导出</el-button>
          <el-button v-if="user.userId != solutionCompound.reviewer" size="small" plain class="return"
                     @click="$emit('changePageGroup', true,1)">返回列表页
          </el-button>
        </div>
      </div>
      <table class="table">
        <tr>
          <th>方案编号</th>
          <td colspan="2">{{ activeInfo.number }}</td>
          <th>仪器型号</th>
          <td colspan="2">
            <template v-for="item in  solutionCompound.instrument">{{ item.instruModel }}、</template>
          </td>
        </tr>
        <tr>
          <td colspan="6">配制品类别：{{ solutionCompound.sort }}</td>
        </tr>
        <tr>
          <td colspan="6" class="specTd">供试品/对照品/配制所用介质</td>
        </tr>
        <tr>
          <td colspan="6" class="specTd way">
            <p>1. 溶媒配制：{{ solutionCompound.solvent }}</p>
            <p>2. 供试品配制：{{ solutionCompound.test }}</p>
            <p>3. 对照品配制：{{ solutionCompound.reference }}</p>
          </td>
        </tr>
        <tr>
          <td>名称</td>
          <td width="120">批号</td>
          <td width="120">性状</td>
          <td>保存条件</td>
          <td>有效期</td>
          <td>提供单位</td>
        </tr>
        <tr v-for="item in solutionCompound.solutionMediumList">
          <td>{{ item.name }}</td>
          <td>{{ item.batchNumber }}</td>
          <td>{{ item.character }}</td>
          <td>{{ item.storageCondition }}</td>
          <td>{{ item.validUntil | formatDay }}</td>
          <td>{{ item.supplier }}</td>
        </tr>
        <tr>
          <td>供试品配置条件</td>
          <td colspan="5">{{ solutionCompound.testCondition }}</td>
        </tr>
        <tr>
          <td>供试品有效期</td>
          <td colspan="5">{{ solutionCompound.testUsefulLife | formatDay }}</td>
        </tr>
        <tr>
          <td>剩余液处理方法</td>
          <td colspan="5">{{ solutionCompound.testResidueHandle }}</td>
        </tr>
        <tr>
          <td>操作人</td>
          <td colspan="2">{{ solutionCompound.createName }}</td>
          <td>日期</td>
          <td colspan="2">{{ solutionCompound.createTime | formatDay }}</td>
        </tr>
        <tr>
          <td>复核人</td>
          <td colspan="2">{{ solutionCompound.reviewName }}</td>
          <td>日期</td>
          <td colspan="2">{{ solutionCompound.reviewTime | formatDay }}</td>
        </tr>
      </table>
      <!--      复核-->
      <h4 class="title">供试品配置复核</h4>
      <template v-if="solutionCompound.state==1&&user.userId == solutionCompound.reviewer ">
        <el-form ref="formCheck" size="small" :model="formCheck" class="addForm" label-suffix=":"
                 label-width="90px">
          <el-form-item label="复核结果">
            <el-radio-group v-model="formCheck.state" class="radioGroup">
              <el-radio :label="2">复核通过</el-radio>
              <el-radio :label="3">返回修改(复核意见必填)</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="复核意见" prop="reviewRemark"
                        :rules="validateType">
            <el-input type="textarea" rows="4" placeholder="请输入复核意见" v-model="formCheck.reviewRemark"></el-input>
          </el-form-item>
          <div class="fromSave">
            <el-button type="primary" size="small" @click="checkSubmit('formCheck')">复核完成</el-button>
          </div>
        </el-form>
      </template>
      <template v-else>
        <div class="desc">
          <p>复核结果：{{ solutionCompound.state == 2 ? '复核通过' : solutionCompound.state == 3 ? '复核拒绝' : '' }}</p>
          <p>复核意见：{{ solutionCompound.reviewRemark }}</p>
          <span>复核人：{{ solutionCompound.reviewName }}</span>
          <span>复核日期：{{ solutionCompound.reviewTime | formatDay }}</span>
        </div>
      </template>
    </div>
  </div>

</template>

<script>
import FileSaver from "file-saver";
import moment from "moment";
import XLSX from "xlsx";
import {mapState} from "vuex";

moment.locale("zh-cn")

export default {
  name: "preparationEnd",
  props: ['id'],
  data() {
    return {
      activeInfo: {},
      solutionCompound: {},
      formCheck: {
        state: 2,
        reviewRemark: ""
      },
      rules: {
        reviewRemark: [{required: true, message: '请输入拒绝原因', trigger: 'blur'}]
      },
    }
  },
  computed: {
    ...mapState({
      user: state => state.account.user
    }),
    validateType() {
      return this.formCheck.state === 3
          ? [{required: true, message: '请输入复核意见', trigger: 'blur'}]
          : []
    },
  },
  mounted() {
    this.$get("/subject/" + this.id).then((res) => {
      if (res.status == 200) {
        this.activeInfo = res.data
      }
      // console.log(res)
    })
    this.getData()
  },
  methods: {
    getData() {
      this.$get('/solution/compound/' + this.id).then(res => {
        if (res.status == 200) {
          res.data.instrument = JSON.parse(res.data.instrument)
          this.solutionCompound = res.data
        }
      })
    },
    // 导出
    exportExcel() {
      let name = moment(new Date()).format('YYYY-MM-DD') + '药前体重、瘤径记录表';
      let wb = XLSX.utils.table_to_book(document.querySelector(".table"));
      let wbout = XLSX.write(wb, {
        bookType: "xlsx",
        bookSST: true,
        type: "array"
      });
      try {
        //  name+'.xlsx'表示导出的excel表格名字
        FileSaver.saveAs(
            new Blob([wbout], {type: "application/octet-stream"}),
            name + ".xlsx"
        );
      } catch (e) {
        if (typeof console !== "undefined") console.log(e, wbout);
      }
      return wbout;
    },
    // 提交复核
    checkSubmit(checkForm) {
      this.$refs[checkForm].validate((valid) => {
        if (valid) {
          this.formCheck.subId = this.id
          this.$put('/solution/compound/state', this.formCheck).then(res => {
            if (res.status == 200) {
              this.$message.success('提交成功')
              this.$router.go(-1)
            }
          })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/exper/experiDetail";
</style>
